<template>
    <div>
        <section class="innerBanner pt-1">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="back-btn mb-4">
                            <router-link to="/">
                                <span class="backIcon">
                                    <span class="icon-arrow"></span>
                                </span>
                                Back
                            </router-link>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pageTitle">
                            <h1>Wishlist</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pageWrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">

                        <!-- Cart Empty Condition Start -->

                        <div class="cartContent no-item-cart" v-if="wishlistQuantity === 0 ">
                            <span class="icon-empty"></span>
                            <p>There is no any items on Wishlist</p>
                        </div> 

                        <div v-else class="cartContent">
                            <div class="cartList wishList">
                                <ul>
                                    <li v-for="(item,i) in wishlistItems" :key="i">
                                        <router-link to="">
                                            <div class="itemImage">
                                                <img :src="item.thumbnail">
                                            </div>
                                            <div class="itemContent">
                                                <div>
                                                    <h3> {{ item.title }} </h3>
                                                    <p>Type: <span class="brand-color"> {{item.item_type}} </span></p>
                                                    <template v-if="item.is_offer">
                                                        <span class="price" ><del> {{item.display_price}} </del></span>
                                                        <span class="price"> {{ item.display_offer_price }} </span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="price"> {{ item.display_price }} </span>
                                                    </template>
                                                </div>
                                                <div class="cartRemove" @click="callRemovePromise(item)" >
                                                    <span class="icon-trash"></span>
                                                </div>
                                                <div class="moveCart">
                                                    <button @click="callAddtoCartPromise(item)" class="univ-btn py-2 px-3">Move to Cart</button>
                                                </div>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "@/store"
import { mapGetters, mapActions } from "vuex"
export default{
    name: 'MobileWishlist',
    data: () => ({
        userId: store.getters.user.id,
        isAuthenticated: store.getters.authenticated,
    }),
    computed: {
        ...mapGetters(["wishlistItems", "wishlistQuantity"]),
    },
    created() {
        let app = this;
        if(app.isAuthenticated){
            app.$store.dispatch("getWishlistItems",app.userId);
        }
    },
    methods: {
        ...mapActions(["removeWishlistItem","addCartItem",]),
        callRemovePromise(item){
            let app = this;
            app.removeWishlistItem({user_id:app.userId, item_id:item.item_id, item_type:item.item_type}).then(() => {
                app.$root.$emit("wishlist-item-removed", true);
            }).catch(()=>{});
        },
        callAddtoCartPromise(item){
            let app = this;
            app.addCartItem({user_id:app.userId, item_id:item.item_id, item_type:item.item_type}).then(() => {
                app.callRemovePromise(item);
            }).catch(()=>{});
        },
    }
}
</script>